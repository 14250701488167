import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: No encontrado" />
    <h1>404: No Encontrado</h1>
    <p>Encontraste una p&aacute; que no existe... que tristeza.</p>
  </Layout>
)

export default NotFoundPage
